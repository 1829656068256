<template>
  <div>
    <TermsForm
      :language="language"
      :terms-type="termsType"
      :version="version"
    ></TermsForm>
  </div>
</template>

<script>
import TermsForm from '@/components/TermsForm';

export default {
  name: 'MarketTerms',
  components: { TermsForm },

  computed: {
    language() {
      const acceptLanguage = navigator.language;
      const split = acceptLanguage.split('-');
      return split[0].toLowerCase().trim();
    },

    termsType() {
      return process.env.VUE_APP_MARKET_TERMS_TYPE;
    },

    version() {
      return process.env.VUE_APP_MARKET_TERMS_VERSION;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
