<template>
  <div>
    <MarketTerms />
  </div>
</template>

<script>
// @ is an alias to /src
import MarketTerms from '@/components/MarketTerms.vue';

export default {
  name: 'MarketTermsPage',
  components: {
    MarketTerms,
  },
};
</script>
